@import "../../Root/common.scss";

.wrapper {
  width: 98vw;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: $footer-height;
  background-color: $border-first-color;
  a {
    display: block;
    text-align: center;
    color: $text-first-color;
  }
}
