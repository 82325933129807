@import "../../Root/common.scss";

.wrapper {
  width: 98vw;
  margin: 0 auto;
  display: flex;
  // flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  height: $header-height;
  background-color: $border-first-color;
  border-bottom: 2px solid $fifth-color;
  text-align: center;
  z-index: 10;

  h1 {
    flex-basis: 40%;
    color: $fourth-color;
  }

  .buttons {
    flex-basis: 40%;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .loginAppBtn,
    .testAppBtn,
    .menuItem {
      flex-basis: 10%;
    }
    .loginAppBtn {
      margin-bottom: 10px;
    }
  }
  @media screen and (min-width: map-get($breakpoints, medium)) {
    height: 70px;
    .buttons {
      flex-direction: row;
      justify-content: space-between;
      .loginAppBtn {
        margin-bottom: 0;
      }
    }
  }
}
