@import "../../../Root/common.scss";

.mainWrapper {
  position: relative;
  margin-bottom: 40px;

  .wrapper {
    display: flex;
    flex-direction: column;
    width: 90vw;
    margin: 120px auto 40px auto;
    padding: 20px;

    .header {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;

      h2 {
        text-align: center;
        flex-basis: 90%;
        margin-bottom: 20px;
      }

      .client,
      .carrier {
        flex-basis: 45%;
        padding: 10px;

        p:nth-child(1) {
          text-transform: uppercase;
          font-weight: 700;
          padding-bottom: 10px;
        }
      }
    }

    .order {
      margin: 20px auto;
      padding-bottom: 20px;
      border-bottom: 1px solid black;

      .load,
      .unload {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;
        border-bottom: 1px solid black;

        .date,
        .place {
          flex-basis: 90%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
        }
      }

      .cargo {
        display: flex;
        flex-direction: column;

        h3 {
          margin-bottom: 10px;
        }

        .specyfication {
          display: flex;
          flex-wrap: wrap;
          align-items: center;

          div {
            display: flex;
            justify-content: flex-start;

            p {
              margin-right: 10px;
            }
          }

          div:nth-child(3) {
            flex-basis: 100%;
          }
        }

        .drivers {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          div {
            display: flex;
            justify-content: flex-start;
            margin-right: 20px;

            p {
              margin-right: 10px;
            }
          }
        }

        .fix {
          div {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            p {
              margin-right: 10px;
            }
          }
        }

      }


    }

    .conditions {
      display: flex;
      justify-content: space-around;

      div {
        flex-basis: 20%;
        display: flex;
        justify-content: space-between;
      }
    }

    .companyData,
    .signatures {
      display: none;
    }
  }

  .buttons {
    display: flex;
    justify-content: space-around;
  }
}

@media print {

  .wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
    border: 1px solid black;
    border-radius: 5px;

    .header {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      padding-bottom: 10px;
      border-bottom: 1px solid black;

      h2 {
        text-align: center;
        flex-basis: 90%;
        margin: 15px;
        font-size: 12pt;
      }

      .client,
      .carrier {
        flex-basis: 50%;
        padding: 10px;
        font-size: 10pt;

        p:nth-child(1) {
          text-transform: uppercase;
          font-weight: 700;
          padding-bottom: 10px;
        }
      }
    }

    .order {
      width: 100%;
      margin: 10px auto;

      .load,
      .unload {
        display: flex;
        flex-wrap: wrap;
        // margin-bottom: 20px;
        padding-bottom: 10px;
        border-bottom: 1px solid black;
        font-size: 10pt;

        .date,
        .place {
          flex-basis: 90%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
          margin: 10px auto;
        }
      }

      .cargo {
        border-bottom: 1px solid black;
        padding-bottom: 20px;
        display: flex;
        flex-direction: column;
        font-size: 8pt;
        margin-top: 20px;

        h3 {
          margin-bottom: 10px;
          font-size: 10pt;
          flex-basis: 100%;
        }

        .specyfication {
          display: flex;
          flex-wrap: wrap;
          align-items: center;

          div {
            display: flex;
            justify-content: flex-start;

            p {
              margin-right: 10px;
            }

            span {
              font-weight: 700;
            }
          }

          div:nth-child(3) {
            flex-basis: 100%;

            span {
              font-weight: 700;
            }
          }
        }

        .drivers {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          div {
            display: flex;
            justify-content: flex-start;
            margin-right: 20px;

            p {
              margin-right: 10px;
            }

            span {
              font-weight: 700;
            }
          }
        }

        .fix {
          div {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            p {
              margin-right: 10px;
            }

            span {
              font-weight: 700;
              color: $text-warring-color;
              text-align: center;
            }
          }

          .fixWorrning {
            p {
              text-align: center;
              font-weight: 700;
            }
          }
        }

      }
    }

    .conditions {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      padding-bottom: 10px;
      border-bottom: 1px solid black;

      div {
        flex-basis: 40%;
        display: flex;
        justify-content: space-between;
      }
    }

    .companyData {
      width: 100%;
      margin: 0 auto;
      padding: 0 10px;

      ul {
        li {
          list-style: decimal;
          font-size: 8pt;
        }

        li:nth-child(3) {
          font-weight: 700;
          color: red;
        }
      }
    }

    .signatures {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      padding-bottom: 20px;
    }
  }


}

@page {
  size: auto;
  margin: 15mm;
}