@import "../../Root/common.scss";

@keyframes ldioMgr4cnjavzn-r {
  0%,
  100% {
    animation-timing-function: cubic-bezier(0.2 0 0.8 0.8);
  }
  50% {
    animation-timing-function: cubic-bezier(0.2 0.2 0.8 1);
  }
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes ldioMgr4cnjavzn-s {
  0%,
  100% {
    animation-timing-function: cubic-bezier(0.2 0 0.8 0.8);
  }
  50% {
    animation-timing-function: cubic-bezier(0.2 0.2 0.8 1);
  }
  0% {
    transform: translate(-30px, -30px) scale(0);
  }
  50% {
    transform: translate(-30px, -30px) scale(1);
  }
  100% {
    transform: translate(-30px, -30px) scale(0);
  }
}
.ldioMgr4cnjavzn > div {
  transform: translate(0px, -15px);
}
.ldioMgr4cnjavzn > div > div {
  animation: ldioMgr4cnjavzn-r 1s linear infinite;
  transform-origin: 100px 100px;
}
.ldioMgr4cnjavzn > div > div > div {
  position: absolute;
  transform: translate(100px, 82px);
}
.ldioMgr4cnjavzn > div > div > div > div {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: $fifth-color;
  animation: ldioMgr4cnjavzn-s 1s linear infinite;
}
.ldioMgr4cnjavzn > div > div:last-child {
  animation-delay: -0.5s;
}
.ldioMgr4cnjavzn > div > div:last-child > div > div {
  animation-delay: -0.5s;
  background: $text-information-color;
}
.loadingioSpinnerInterwindAomtfdrgrj6 {
  position: fixed;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 200px;
  display: block;
  overflow: hidden;
}
.ldioMgr4cnjavzn {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldioMgr4cnjavzn div {
  box-sizing: content-box;
}
/* generated by https://loading.io/ */
