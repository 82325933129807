@import "../../../Root/common.scss";

.selectButton {
  min-width: 100px;
  padding: 5px 10px;
  background-color: $third-color;
  border: 2px solid $fifth-color;
  border-radius: 5px;
  font-size: $s-font-size/1.2;
  cursor: pointer;
  transition: 0.4s;
  &:hover {
    background-color: $border-first-color;
    color: $fourth-color;
  }
  @media screen and (min-width: map-get($breakpoints, medium)) {
    font-size: $s-font-size;
  }
}
.buttonDisabled {
  min-width: 100px;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: $s-font-size/1.2;
  @include buttonDisabled;
}
