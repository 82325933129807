@import "../../../Root/common.scss";

.wrapper {
  @include sectionWrapper;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  h2 {
    margin: 20px auto;
    color: $text-second-color;
  }
  .client,
  .carrier,
  .order,
  .conditions {
    flex-basis: 30%;
    padding: 20px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 50px;
    border: 2px solid $fifth-color;
    border-radius: 5px;

    .dataInfo {
      display: flex;
      flex-basis: 100%;
      justify-content: space-evenly;
      margin-bottom: 10px;
      p {
        flex-basis: 30%;
        color: $text-second-color;
        font-weight: 700;
      }
      div {
        flex-basis: 65%;
        color: $text-second-color;
        font-weight: 700;
      }
    }
    .dataInfoTable {
      margin-bottom: 10px;

      tr {
        color: $text-second-color;
      }
      th,
      td {
        width: 100px;
        text-align: center;
      }
    }
    .buttons {
      flex-basis: 90%;
      display: flex;
      justify-content: space-around;
    }
  }
  .operationButtons {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
  }
  .backButton {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
  }
  .spinnerWrapper {
    @include spinnerWrapper;
  }
  @media screen and (min-width: map-get($breakpoints, medium)) {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    h2 {
      flex-basis: 100%;
    }
    .client,
    .carrier,
    .order,
    .conditions {
      flex-basis: 45%;
      margin-left: 20px;
    }
    .operationButtons {
      flex-basis: 60%;
      align-items: flex-start;
    }
    .backButton {
      height: 40px;
      align-items: flex-start;
    }
  }
}
