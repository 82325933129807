@import "../../../Root/common.scss";
.wrapper {
  @include sectionWrapper;
  h1 {
    padding-top: 20px;
    padding-bottom: 20px;
    margin: 0 auto;
    text-align: center;
    font-size: $l-font-size;
  }

  .selectButtons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    height: 200px;
    button {
      width: 160px;
      margin-bottom: 10px;
    }
    form {
      display: flex;
      justify-content: center;
      ::placeholder {
        color: lighten($text-second-color, 50);
        text-align: center;
      }
      input {
        height: 30px;
        border: 1px solid $fifth-color;
        border-radius: 5px;
        background-color: $second-color;
        color: $text-second-color;
        font-weight: 700;
      }
      button {
        width: 30px;
        height: 30px;
        background-color: transparent;
        border: none;
        cursor: pointer;
      }
    }
  }

  @media screen and (orientation: landscape) {
    .selectButtons {
      button {
        width: 140px;
      }
    }
  }
  @media screen and (min-width: map-get($breakpoints, medium)) {
    h1 {
      font-size: $xl-font-size;
    }
    .selectButtons {
      button {
        width: 180px;
      }
    }
    .ordersList {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
    }
  }
  @media screen and (min-width: map-get($breakpoints, large)) {
    .selectButtons {
      button {
        width: 180px;
      }
    }
  }
}
