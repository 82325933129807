@import "../../../Root/common.scss";

.wrapper {
  position: relative;
  width: 70vw;
  h3 {
    margin-bottom: 20px;
    text-align: center;
  }
  .form {
    .inputs {
      div {
        display: flex;
        flex-wrap: wrap;
        ::placeholder {
          color: $fifth-color;
          font-weight: 700;
        }
        input,
        textarea {
          @include inputViev;
        }
        span {
          flex-basis: 90%;
          color: $text-warring-color;
          text-align: left;
          font-weight: 700;
        }
      }
    }
  }
  .buttons {
    display: flex;
    justify-content: space-around;
    margin: 20px auto;
  }
  .spinnerWrapper {
    @include spinnerWrapper;
  }
  @media screen and (orientation: landscape) {
    width: 60vw;
    height: 80vh;
  }
  @media screen and (min-width: map-get($breakpoints, medium)) {
    width: 40vw;
    height: 50vh;
  }
}
