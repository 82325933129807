@import "../../Root/common.scss";
.wrapper {
  padding: 20px;
  background-color: $text-warring-color;
  border-radius: 5px;
  h3 {
    text-align: center;
  }
  .buttons {
    display: flex;
    margin-top: 30px;
    justify-content: space-around;
  }
}
