@import "../../Root/common.scss";

.clientData {
  position: relative;
  // height: 420px;
  margin: 10px auto;
  padding: 20px;
  border: 3px solid $fifth-color;
  border-radius: 5px;
  background-color: $basic-color;
  h3 {
    text-align: center;
    margin-bottom: 20px;
    border-bottom: 2px solid $fifth-color;
  }
  .item {
    margin-bottom: 5px;
    p {
    }
    p:nth-child(1) {
      font-weight: 700;
    }
  }
  .clientButtons {
    // position: absolute;
    // bottom: 10px;
    // left: 50%;
    // transform: translateX(-50%);
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  @media screen and (orientation: landscape) {
    .item {
      display: flex;
      justify-content: left;
      p:nth-child(1) {
        margin-right: 20px;
      }
    }
  }
  @media screen and (min-width: map-get($breakpoints, medium)) {
    flex-basis: 48%;
  }

  @media screen and (min-width: map-get($breakpoints, large)) {
    flex-basis: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0px;
    h3 {
      flex-basis: 100%;
      padding-bottom: 10px;
      text-align: left;
    }
    .item {
      justify-content: space-between;
      margin: 0 10px;
      border-right: 2px solid$fifth-color;
      padding-right: 10px;
      p {
        font-size: $s-font-size;
      }
      p:nth-child(1) {
        margin-right: 10px;
      }
    }
    .clientButtons {
      position: absolute;
      top: 0;
      left: 70%;
      width: 30%;
    }
  }
}
