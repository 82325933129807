@import "../../../Root/common.scss";

.wrapper {
  width: 90%;
  margin: 10px auto;
  padding: 10px;
  border: 1px solid $fifth-color;
  border-radius: 5px;
  color: $text-second-color;
  background-color: transparent;
  .item {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 5px;
  }
  .orderData {
    margin-top: 20px;
    padding-top: 10px;
    border-top: 1px solid $fifth-color;
    .item:nth-child(2),
    .item:nth-child(5),
    .item:nth-child(8),
    .item:nth-child(13) {
      border-bottom: 1px solid $fourth-color;
      padding-bottom: 10px;
      padding: 5px 10px;
    }
  }
  .buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 15px;
    padding-top: 10px;
    border-top: 1px solid $fifth-color;
    button {
      margin-bottom: 15px;
    }
  }
  @media screen and (min-width: map-get($breakpoints, medium)) {
    width: 48%;
  }
  @media screen and (min-width: map-get($breakpoints, large)) {
    width: 33%;
  }
}
