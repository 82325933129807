@import "../../Root/common.scss";

.wrapper {
  @include sectionWrapper;
  h1 {
    padding-top: 20px;
    padding-bottom: 20px;
    margin: 0 auto;
    text-align: center;
    font-size: $l-font-size;
  }

  .selectButttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    height: 200px;
    button {
      width: 160px;
      margin-bottom: 10px;
    }
  }
  @media screen and (orientation: landscape) {
    .selectButttons {
      button {
        width: 140px;
      }
    }
  }
  @media screen and (min-width: map-get($breakpoints, medium)) {
    h1 {
      font-size: $xl-font-size;
    }
    .selectButttons {
      button {
        width: 160px;
      }
    }
  }
  @media screen and (min-width: map-get($breakpoints, large)) {
    .selectButttons {
      button {
        width: 180px;
      }
    }
  }
}
