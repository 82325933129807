@import "../../../Root/common.scss";

.backButton {
  padding: 5px 10px;
  background-color: $fifth-color;
  color: $fourth-color;
  border: 1px solid $text-warring-color;
  font-size: $s-font-size;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.4s;
  &:hover {
    background-color: $border-first-color;
  }
  @media screen and (min-width: map-get($breakpoints, medium)) {
    font-size: $l-font-size/1.1;
  }
}
