@import "../../Root/common.scss";

.wrapper {
  @include sectionWrapper;
  margin-bottom: 20px;
  h1 {
    text-align: center;
    font-size: $m-font-size * 1.1;
  }
  ul {
    margin: 30px auto;
    li {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin: 20px auto;
      p,
      button,
      a {
        margin-left: 5px;
        font-size: $xs-font-size;
      }
    }
  }
  ul :nth-child(2) {
    font-weight: 700;
  }
  @media screen and (min-width: map-get($breakpoints, medium)) {
    margin-bottom: 0;
    h1 {
      font-size: $xl-font-size;
    }
    ul {
      margin: 50px auto;
      li {
        p,
        button,
        a {
          margin-left: 10px;
          font-size: $l-font-size;
        }
      }
    }
  }
}
