@import "./variables.scss";

@mixin sectionWrapper {
  position: relative;
  width: 90vw;
  min-height: calc(100vh - 160px);
  margin: 40px auto 0 auto;
  @media screen and (min-width: map-get($breakpoints, medium)) {
    // margin: 100px auto 0;
  }
}
@mixin backButtonPosition {
  position: absolute;
  right: 20px;
  bottom: 20px;
}
@mixin spinnerWrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  padding: 10px;
  z-index: 100;
}

@mixin buttonDisabled {
  background-color: $basic-color;
  color: $first-color;
  &:hover {
    background-color: $basic-color;
    color: $first-color;
  }
}
@mixin inputViev {
  height: 35px;
  width: 90%;
  margin-top: 10px;
  background-color: $basic-color;
  border: none;
  border-bottom: 2px solid $fifth-color;
  font-size: $m-font-size;
  color: $fourth-color;
  @media screen and (min-width: map-get($breakpoints, lm)) {
    font-size: $l-font-size/1.2;
  }
}
