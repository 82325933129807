@import "../../../Root/common.scss";

.button {
  position: fixed;
  right: 20px;
  bottom: 20px;
  padding: 10px;
  background-color: $text-information-color;
  font-size: $m-font-size;
  font-weight: 700;
  border: 2px solid $fifth-color;
  border-radius: 5px;
  cursor: pointer;
  opacity: 0.7;
  &:hover {
    opacity: 1;
  }
}
