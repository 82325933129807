@import "../../Root/common.scss";

.items {
  position: fixed;
  top: -130px;
  left: 0;
  width: 100%;
  height: 120px;
  background-color: $text-second-color;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 2px solid $fifth-color;
  transition: 0.5s;
  z-index: 10;
  a,
  button {
    display: block;
    width: 130px;
    color: $text-first-color;
    // padding: 5px 10px;
    font-size: $xs-font-size;
    text-decoration: none;
    border: 1px solid $fifth-color;
    border-radius: 5px;
    &:hover {
      background-color: $text-first-color;
      color: $text-second-color;
    }
  }
  @media screen and (min-width: map-get($breakpoints, medium)) {
    height: 100px;
  }
}
