$basic-color: #8b93a6;
$first-color: #37404f;
$second-color: #c9cfd3;
$third-color: #3c6f8a;
$fourth-color: #ffffee;
$fifth-color: #034798;

$text-first-color: #fff;
$text-second-color: #000;
$text-third-color: #aaa;
$text-information-color: #339fcd;
$text-warring-color: #e03202;

$border-first-color: #000;

$xs-font-size: 14px;
$s-font-size: 16px;
$m-font-size: 18px;
$l-font-size: 20px;
$x-font-size: 24px;
$xl-font-size: 28px;

$header-height: 110px;
$footer-height: 50px;

$breakpoints: (
  medium: 800px,
  large: 1200px,
);
