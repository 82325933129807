@import "../../../Root/common.scss";

.mainButton {
  min-width: 80px;
  padding: 5px 10px;
  background-color: $first-color;
  color: $fourth-color;
  font-size: $xs-font-size;
  border: 2px solid $third-color;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.4s;
  &:hover {
    background-color: $third-color;
  }
}
.buttonDisabled {
  min-width: 80px;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: $s-font-size/1.2;
  @include buttonDisabled;
}
